img{
  width: 100%;
}

.freeShipping{
  background-color: #ffff00;
  color: #000000;
  margin: 0px;
  padding: 2px 10px 2px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.categoryOverview{
  padding: 0em 0.4em 0em 0.4em;
  font-size: 13px;
  color: #000000;
  height: 3.2em;
  overflow: hidden;
  margin-bottom: 0.5em;
}

.specialButton {
  margin: 0.4em 0.5em 0.4em 0.5em;
  background-color: #ffffff;
  border: 0.15em solid #dfdfdf;
  border-radius: 0.2em;
  color: #000000;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  padding: 0.4em 1em 0.2em 1em;
  transition: 0.4s;
  min-width: 250px;
}

@media (max-width: 450px) {
  .specialButton {
    padding: 0.4em 0.4em 0.2em 0.4em;
    font-size: 0.7em;
    min-width: 130px;
  }
}

.specialButton:hover,
.specialButton:focus {
  background-color: #00afef;
  border: 0.15em solid #00afef;
  color: #ffffff;
}

body{
  font-family: 'Montserrat', sans-serif;
}

/********* Header *********/

@media (min-width: 1400px) {
  .customContainer{
    max-width: 1200px;
  }
}

.logo {
  width: 9em;
}

#headerItem {
  margin: 0.6em 1.8em 0em 1.8em;
  font-size: 0.9em;
  font-weight: 600;
  color: #000000;
  transition: 0.4s;
}

@media (max-width: 450px) {
  #headerItem {
    margin: 0.2em 1.5em 0em 1.5em;
  }
}

#headerItem:hover {
  color: #c1d101;
}

/********* Footer *********/

footer {
  position: relative;
  bottom: -10em;
  margin-top: 3em;
  padding: 4em 1em 3em 1em;
  background-color: #ededed;
  text-align: center;
}

.footerCol {
  padding: 0.5em;
}

.footerItem {
  margin: 0.1em;
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: none;
  color: #252525;
  transition: 0.4s;
}

/********* ContactScreen *********/

.contactCol {
  text-align: center;
  padding: 0.8em 2em 0.8em 2em;
}

.customDealerContainer{
  margin-bottom: 6em;
}

.dealerRow {
  align-items: center;
  margin-top: 1em;
  border: 0.1em solid #dfdfdf;
  border-radius: 0.2em;
  text-align: center;
  padding: 0.4em 0.2em 0.4em 0.2em;
  margin: 0em 0.3em 1em 0.3em;
}

@media (min-width: 992px) {
  .dealerRow {
    margin: 0em 4em 1em 4em;
  }
}

.dealerCol{
  padding: 0.5em 1em 0.5em 1em;
}

.dealerCol-2{
  padding: 0em 1em 0.5em 1em;
}

@media (min-width: 992px) {
  .dealerCol-2{
    padding: 0.5em 1em 0.5em 1em;
  }
}

@media (max-width: 992px) {
  .dealerImage{
    margin-left: 0.8em;
  }
}

.dealerCity {
  font-size: 0.95em;
  margin: 0;
  font-weight: 600;
  color: #000000;
}

.dealerP{
  font-size: 0.95em;
  margin: 0;
  color: #000000;
  display: inline;
}

.dealerP-2{
  font-size: 0.95em;
  font-weight: 500;
  margin: 0;
  color: #000000;
  display: inline;
}

.contactItem {
  font-size: 0.98em;
  margin: 0em;
  text-decoration: none;
  color: #000000;
}

.mailIcon {
  color: #007ae7;
}

.phoneIcon {
  color: #ee0000;
}

.wpIcon {
  color: #4bca5a;
}

.socialIcon {
  margin: 1.5em;
  color: #000000;
  transition: 0.4s;
}

.socialIcon:hover {
  color: #555555;
}

iframe {
  margin-top: 1em;
  width: 100%;
  height: 20em;
}

/********* ReferenceScreen *********/

.referenceRow {
  padding: 0em 1.2em 0em 1.2em;
}

@media (min-width: 992px) {
  .referenceRow {
    padding: 0em 4em 0em 4em;
  }
}

.referenceCol {
  text-align: center;
  padding: 1em 1.5em 1em 1.5em;
}

/********* CategoryScreen *********/

.categoryItem {
  border: 0.15em solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 0.2em;
  transition: 0.2s;
  text-align: center;
}

.categoryItem:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

.categoryItem-2 {
  margin-bottom: 0.5em;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.2em;
  transition: 0.2s;
  text-align: center;
}

.categoryRow {
  padding: 0em 0.4em 0em 0.4em;
}

.categoryRow-2 {
  padding: 0em 1.2em 0em 1.2em;
}

.categoryCol {
  text-align: center;
  padding: 0.6em 0.4em 0em 0.4em;
}

.categoryCol-4 {
  text-align: center;
  padding: 0.6em 0.4em 1.2em 0.4em;
}

.categoryCol-3 {
  text-align: center;
  padding: 0.6em 1em 1.2em 1em;
}

.categoryCol-2 {
  text-align: center;
  padding: 0.6em 1em 1.2em 1em;
}

.categoryImage {
  border-radius: 100%;
  border: 0.15em solid #000000;
  padding: 1em 0em 1em 0em;
  transition: 0.4s;
}

.categoryImage:hover {
  border: 0.15em solid #00afef;
}

.categoryImage-3 {
  border-radius: 100%;
  border: 0.2em solid #004b1d;
  transition: 0.2s;
}

.categoryImage-3:hover {
  border: 0em solid #c1d101;
}

.categoryHeader {
  text-transform: uppercase;
  padding: 0em;
  font-size: 1.05em;
  font-weight: 600;
  margin-top: 0.8em;
  margin-bottom: 0em;
  color: #004b1d;
}

@media (min-width: 992px) {
  .categoryRow {
    padding: 0em 4em 0em 4em;
  }

  .categoryRow-2 {
    padding: 0em 12em 2em 12em;
  }

  .categoryCol {
    padding: 0.5em 1.2em 0.5em 1.2em;
  }

  .categoryCol-4 {
    padding: 0.5em 1.5em 0.5em 1.5em;
  }

  .categoryCol-3 {
    padding: 0.5em 1.2em 0.5em 1.2em;
  }

  .categoryCol-2 {
    padding: 0.5em 2em 2em 2em;
  }
}

/********* ProductsScreen *********/

.categoryImage-2 {
  padding: 1.5em 0em 1.5em 0em;
  transition: 0.2s;
}

.categoryImage-4 {
  border-radius: 0.2em 0.2em 0em 0em;
  transition: 0.2s;
  opacity: 0.8;
}

.categoryImage-4:hover {
  opacity: 1;
}

@media (min-width: 992px) {
  .categoryImage-2 {
    padding: 2.5em 0em 2.5em 0em;
  }
}

.categoryHeader-4 {
  text-transform: uppercase;
  padding: 1em 0.4em 0em 0.4em;
  font-size: 0.95em;
  font-weight: 600;
  color: #000000;
  padding-bottom: 1em;
}

.categoryHeader-2 {
  padding: 0em 0.4em 0em 0.4em;
  font-size: 0.90em;
  font-weight: 600;
  color: #000000;
}

.categoryHeader-3 {
  padding: 0em 0.4em 0em 0.4em;
  font-size: 0.85em;
  font-weight: 600;
  color: #000000;
}

.categoryP {
  font-size: 0.9em;
  height: 3.2em;
  margin-left: 0.4em;
  margin-right: 0.4em;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000000;
  font-weight: 400;
}

.categoryButton {
  padding: 0.3em 0.9em 0.2em 0.9em;
  margin: 0.2em 0em 1em 0em;
  background-color: #ffffff;
  border: 1px solid #c1d101;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #c1d101;
  font-size: 15px;
}

@media (max-width: 350px) {
  .categoryButton {
    display: none;
  }
}

@media (min-width: 485px) {
  .categoryButton {
    margin: 0.2em 0em 1em 0em;
    padding: 0.5em 2em 0.4em 2em;
  }
}

.categoryCol:hover .categoryButton {
  background-color: #c1d101;
  border: 1px solid #c1d101;
  color: #ffffff;
}

.categoryCol:hover .categoryButton:hover {
  background-color: #aab800;
  border: 1px solid #aab800;
  color: #ffffff;
}

.categoryDescriptionCol {
  border-radius: 0.2em;
  margin-top: 2em;
  padding: 1em 1.4em 0.2em 1.4em;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.1);
}

.categoryDescriptionP {
  line-height: 2em;
  color: #000000;
}

/********* ProductScreen *********/

.customTable {
  background-color: #dfdfdf;
  border-color: #ffffff;
  color: #000000;
  text-shadow: 0.01em 0.01em #000000;
}

.errorRow {
  padding: 3em 1em 12em 1em;
}

.productSoon {
  padding: 8em 0em 8em 0em;
  text-align: center;
}

.productError {
  padding: 2.5em;
  background-color: #f7dddc;
  border: 0.1em solid #ae150c;
  border-radius: 0.3em;
  text-align: center;
}

.productErrorP {
  text-transform: uppercase;
  margin: 0em;
  font-size: 2em;
  color: #000000;
  text-shadow: 0.02em 0.02em #000000;
}

.error404P {
  margin: 0em;
  font-size: 6em;
  color: #000000;
  text-shadow: 0.02em 0.02em #000000;
}

.breadcrumbCol-3 {
  margin: 2.5em 0em 0em 0em;
}

.breadcrumbCol {
  text-align: right;
  margin: 2.1em 0em 0em 0em;
}

.breadcrumbCol-2 {
  margin: 0em 0.6em 0em 0.6em;
}

@media (max-width: 767px) {
  .breadcrumbCol {
    margin: 2em 0.4em 0em 0.4em;
    text-align: center;
  }

  .breadcrumbCol-3 {
    margin: 2em 0.4em 0em 0.4em;
  }
}

@media (max-width: 365px) {
  .breadcrumbCol-3 {
    display: none;
  }
}

@media (max-width: 920px) {
  .breadcrumbCol-2 {
    margin: 0.3em 0.6em -0.6em 0.6em;
  }
}

.pdfViewer{
  width: 100%;
  height: 76em;
}

.productButtonCol {
  text-align: right;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  padding: 0em 1em 0em 1em;
}

.productButtonCol-2 {
  text-align: right;
  margin-top: 0em;
  margin-bottom: 0.5em;
  padding: 0em 1em 0em 1em;
}

.productCol {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0em 1em 0em 1em;
}

.productCol-2 {
  margin: 4em 0.25em 0em 0.25em;
  padding: 1.5em 0.8em 0.5em 0.8em;
  border-radius: 0.2em;
  border: 0.1em solid #dfdfdf;
}

@media (max-width: 1300px) {
  .productCol-2 {
    margin: 2.5em 0.25em 0em 0.25em;
  }
}

.productCol-3 {
  margin-bottom: 1em;
  border-bottom: 0.1em solid #000000;
}

.productOverviewP {
  padding: 0em 0.6em 0em 0.6em;
  white-space: pre-wrap;
  color: #000000;
}

.productOverviewP-2 {
  padding: 0em 0.6em 0em 0.6em;
  white-space: pre-wrap;
  color: #000000;
  font-size: 1.5em;
  font-weight: 600;
}

.productInfoP {
  white-space: pre-wrap;
  line-height: 2em;
  font-size: 0.9em;
  color: #000000;
}

.productInfoP-3 {
  display: inline;
  white-space: pre-wrap;
  line-height: 2em;
  font-size: 0.98em;
  font-weight: 400;
  color: #000000;
}

.productInfoP-4 {
  white-space: pre-wrap;
  line-height: 2em;
  font-size: 0.9em;
  font-weight: 600;
  color: #000000;
}

.productInfoP-5 {
  white-space: pre-wrap;
  line-height: 2em;
  font-size: 0.98em;
  font-weight: 600;
  color: #000000;
}

.productInfoP-2 {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  color: #000000;
}

.productInfoTable {
  margin-bottom: 3em;
  font-size: 0.9em;
}

.productDatasheet {
  color: #b30b00;
  margin: 0.3em 0.2em 0.3em 0.2em;
}

.productCarousel {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.2em;
}

.carouselItem {
  padding: 0.5em 2em 0.5em 2em;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 11px;
  height: 10px;
  margin-right: 10px;
  margin-left: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000000;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-radius: 100%;
  opacity: 0.4;
  transition: opacity 0.6s ease;
}

@media (min-width: 1400px) {
  .carouselItem {
    padding: 0.5em 5em 0.5em 5em;
  }
}

.productHeader {
  padding: 0.2em 0em 0em 0em;
  font-size: 1.3em;
  font-weight: 500;
  color: #000000;
}

.productHeader-2 {
  margin: 0em;
  font-size: 0.8em;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}

.productHeader-3 {
  display: inline;
  padding: 0.2em 0em 0em 0em;
  font-size: 1.2em;
  font-weight: 600;
  color: #000000;
}

.productHeader-4 {
  padding: 0.1em 0em 0em 0em;
  font-size: 1.1em;
  font-weight: 500;
  color: #000000;
}

.productHeader-5 {
  font-size: 1.5em;
  font-weight: 500;
  color: #000000;
}

.productOverview {
  font-size: 1.2em;
  font-weight: 400;
  color: #000000;
}

.table {
  color: #000000;
}

.requestHeader {
  color: #ffffff;
  text-transform: uppercase;
}

.requestHeader:hover {
  color: #ffffff;
}

.requestButton {
  margin: 0.2em 1em 1em 0.5em;
  background-color: #f26725;
  border: 0.1em solid #f26725;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestButton:hover,
.requestButton:focus {
  background-color: #f26725;
  border: 0.1em solid #f26725;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

.requestWhatsappButton {
  margin: 0.2em 1em 1em 0.5em;
  background-color: #4bca5a;
  border: 0.1em solid #4bca5a;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestWhatsappButton:hover,
.requestWhatsappButton:focus {
  background-color: #4bca5a;
  border: 0.1em solid #4bca5a;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

.requestPdfButton {
  margin: 0.2em 1em 1em 0.5em;
  background-color: #b30b00;
  border: 0.1em solid #b30b00;
  border-radius: 0.2em;
  transition: 0.4s;
  color: #ffffff;
}

.requestPdfButton:hover,
.requestPdfButton:focus {
  background-color: #b30b00;
  border: 0.1em solid #b30b00;
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

.additionalImage{
  margin-bottom: 1em;
}

/********* ServiceScreen - AboutScreen *********/

.aboutCol {
  padding: 1em 2em 2em 2em;
}

@media (max-width: 1200px) {
  .aboutCol {
    padding: 1em 1.5em 2em 1.5em;
  }
}

.aboutP {
  font-size: 0.98em;
  line-height: 2em;
  color: #000000;
}

.aboutImage {
  padding: 3em 0em 1em 0em;
}

.serviceCol {
  padding: 1em;
}

.serviceCol-2 {
  background: rgb(42, 67, 91);
  background: linear-gradient(
    135deg,
    rgba(42, 67, 91, 1) 0%,
    rgba(42, 67, 91, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 0.1em solid #2a435b;
  border-radius: 0.2em;
  transition: 0.2s;
  padding: 2.5em;
}

.serviceCol-2:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

@media (max-width: 1050px) {
  .serviceCol-2 {
    padding: 2em;
  }
}

.serviceImage {
  margin-top: 1em;
  border-radius: 0.3em;
}

.serviceP {
  color: #ffffff;
  line-height: 1.8em;
}

.serviceHeader {
  font-size: 1.2em;
  text-align: center;
  color: #ffffff;
}

/********* AdvertisementCarousel *********/

.carousel-caption {
  text-align: left;
  top: 5em;
}

.carouselHeader {
  margin: 0em 0em 0.6em 0em;
  font-size: 1.5em;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
}

.carouselP {
  margin: -1em 0em 1em 0em;
  font-size: 2em;
  font-weight: 600;
  color: #000000;
  line-height: 2em;
}

.carouselButton {
  margin: 1em 0em 1em 0em;
  background-color: #ff6f00;
  border: 0.1em solid #ff6f00;
  border-radius: 0.2em;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 0.06em 0.06em #000000;
  text-transform: uppercase;
  padding: 0.5em 1.5em 0.5em 1.5em;
  transition: 0.4s;
}

.carouselButton:hover,
.carouselButton:focus {
  background-color: #e35600;
  border: 0.1em solid #e35600;
  color: #ffffff;
}

@media (min-width: 900px) {
  .carousel-caption {
    top: 2em;
  }
}

@media (min-width: 1300px) {
  .carousel-caption {
    top: 5em;
  }
}

@media (max-width: 700px) {
  .carousel-caption {
    top: 0em;
  }

  .carouselButton {
    margin: 0.8em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
  }
}

@media (max-width: 525px) {
  .carousel-caption {
    top: 2em;
  }

  .carouselButton {
    margin: 0.8em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
  }
}

@media (max-width: 450px) {
  .carousel-caption {
    top: 1.5em;
  }

  .carouselP {
    display: none;
  }

  .carouselButton {
    margin: 0.8em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
  }
}

@media (max-width: 350px) {
  .carousel-caption {
    top: 0.5em;
  }

  .carouselHeader {
    font-size: 1.8em;
  }

  .carouselP {
    display: none;
  }

  .carouselButton {
    margin: 0.8em 0em 0em 0em;
    padding: 0.4em 0.8em 0.4em 0.8em;
  }
}

.carousel-item.active .carouselP,
.carousel-item.active .carouselHeader {
  animation: backInDown;
  animation-duration: 1.2s;
}

.carousel-item.active .carouselButton {
  animation: backInUp;
  animation-duration: 1.2s;
}

.carousel-item.active .carouselImage {
  animation: pulse;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

/********* HomeScreen *********/

.homeCol {
  padding: 2em;
}

.homeCol-2 {
  padding: 2em;
}

.homeCol-3 {
  padding: 1em 1em 0.1em 1em;
}

.homeHeader {
  font-size: 1.5em;
  padding: 0em 0.5em 0.2em 0.5em;
  text-transform: uppercase;
  text-align: center;
  color: #004b1d;
  font-weight: 600;
}

.homeP {
  color: #000000;
  padding-bottom: 2em;
  margin: 0em;
  line-height: 2em;
}

.homeHeader-2 {
  font-size: 1.5em;
  color: #000000;
}

.homeP-2 {
  color: #000000;
  margin: 0em;
  line-height: 1.8em;
}

.homeSection {
  padding: 2em 0em 2em 0em;
  background-color: #ededed;
}

.homePopularImage {
  padding: 1em 2em 0em 2em;
}

.popularProductLink {
  padding: 1em;
}

.popularItem {
  border: 0.1em solid #dfdfdf;
  border-radius: 0.2em;
  transition: 0.2s;
  text-align: center;
  padding-bottom: 1em;
}

.popularItem:hover {
  box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.2);
}

@media (min-width: 1300px) {
  .homePopularImage {
    padding: 1em 5em 0em 5em;
  }
}

.homePopularP {
  margin: 0em;
  padding: 1em 2em 1em 2em;
  text-align: left;
}

/********* All *********/

.customRow-3 {
  padding: 0em 0em 0.2em 0em;
}

@media (min-width: 992px) {
  .customRow-3 {
    padding: 0em 4em 0.4em 4em;
  }
}

.customRow {
  padding: 0em;
}

@media (min-width: 992px) {
  .customRow {
    padding: 0em 4em 2em 4em;
  }
}

.customRow-2 {
  text-align: center;
  padding: 2em 2em 1em 2em;
  margin-bottom: -10em;
}

@media (min-width: 992px) {
  .customRow-2 {
    padding: 1em 12em 0em 12em;
    margin-bottom: -10em;
  }
}

.customRow-4 {
  text-align: center;
  padding: 2em 2em 1em 2em;
}

@media (min-width: 992px) {
  .customRow-4 {
    padding: 4em 23em 2em 23em;
  }
}

.headerCol {
  padding-top: 0.8em;
  text-align: center;
}

@media (min-width: 992px) {
  .headerCol {
    padding-top: 1.6em;
    text-align: center;
  }
}

.header-1 {
  margin-top: 1em;
  font-size: 1.6em;
  font-weight: 500;
  color: #000000;
}

.header-2 {
  font-size: 1.5em;
  font-weight: 400;
}

.textDecorationNone {
  text-decoration: none;
}

.textDecorationNone-2 {
  text-decoration: none;
  color: #000000;
}

.textDecorationNone-2:hover {
  text-decoration: underline;
  color: #000000;
}
